import { CalendarItem } from '@/features/calendars/types'
import { usePutPlannerItemMutation } from '@/redux/features/api'
import { NewItem, Item } from '@/types'
import { generateItemId } from '@/utils/item'

export const usePutPlannerItem = () => {
    const [put] = usePutPlannerItemMutation()

    const putItem = (item: NewItem | Item | CalendarItem) => {
        let isNewItem = false
        const newItem = { ...item, ...(!item.type && !item.id && ('dateEnd' in item && item.dateEnd ? { type: 'event' } : { type: 'task' })) }
        if (!newItem.id && item.type) {
            newItem.id = generateItemId(item.type, 'childType' in item ? item.childType : undefined)
            isNewItem = true
        }
        put({ item: newItem as NewItem, isNew: isNewItem || !item.id })
        return newItem.id
    }

    return { putItem }
}
