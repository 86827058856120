import shrinkTextRef from '@/refs/shrinkTextOnOverflow'
import { useCallback } from 'react'

const useShrinkText = (options: { horizontal?: boolean; debug?: string } = {}) => {
    const ref = useCallback((contentEl: HTMLElement) => {
        return shrinkTextRef(contentEl, options)
    }, [...Object.values(options)])
    return ref
}

export default useShrinkText
